import React, { useEffect, useState } from 'react'
import './donate.css'
import { axiosInstance } from '../../axiosConfig';
import { toast } from 'react-toastify';
import { Field, Formik } from 'formik';
import { SubscribeDonateValidationSchema } from '../../validation';
import Images from '../../Images';

const Donate = () => {

  const [accountDetails, setAccountDetails] = useState({});

  useEffect(() => {
    getAccountDetails();
    return () => {
      setAccountDetails({});
    };
  }, []);

  const getAccountDetails = async () => {
    axiosInstance.get(`/setting/detail?type=accountDetails`)
      .then((response) => {
        const responseData = response?.data?.data;
        const { status } = response?.data;
        if (status) {
          setAccountDetails(responseData);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  const initialState = {
    name: '',
    email: '',
    message: '',
    type: 'donate',
  }

  const handleSubmit = async (data, { resetForm }) => {
    axiosInstance.post(`/subscribe`, data)
      .then((response) => {
        const { status, message } = response?.data;
        if (status) {
          resetForm();
          toast.success(message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='donate'>
      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
        </div>
      </section>
      <div className='donate-content'>
        <div className='container'>
          <div className='testimonies-form-img'>
            <img src={Images.DonateImg} alt='' />
          </div>
          <div className='donate-title text-center'><h2 className='text-transform-capitalize'>donate</h2></div>
          <div className='donate-sub-title text-center mt-3'><h6>Your partnership is important to us</h6></div>
          <div className='donate-account-detail'>
            <div className='donate-account-detail-title'><h4>Account Details</h4></div>
            <div className='donate-account-detail-content'>
              <div className='donate-detail donate-account-number mt-4'>
                <h5>Account Number</h5>
                <h6 className='mt-2'>{accountDetails?.accountNumber}</h6>
              </div>
              <div className='donate-detail donate-account-name mt-4'>
                <h5>Account Name</h5>
                <h6 className='mt-2'>{accountDetails?.accountName}</h6>
              </div>
              <div className='donate-detail donate-bank-name mt-4'>
                <h5>Bank Name</h5>
                <h6 className='mt-2'>{accountDetails?.bankName}</h6>
              </div>
            </div>
            <div className='donate-detail-form mt-5'>
              <Formik
                initialValues={initialState}
                onSubmit={handleSubmit}
                validationSchema={SubscribeDonateValidationSchema}
              >
                {({ errors, touched, handleSubmit }) => {
                  return (

                    <form onSubmit={handleSubmit} id="contact" method="post" noValidate="novalidate">

                      <div className="donate-detail-form-title">
                        <h6>We would love to say thank you, kindly share your information..</h6>
                      </div>

                      <div className="donate-detail-form-field">

                        <div className='form-field'>
                          <Field name="name" type="text" className="form-control name-input" id="name" placeholder="Full Name" required />
                          {errors.name && touched.name && <label className="error" htmlFor="name">{errors.name}</label>}
                        </div>
                        <div className='form-field'>
                          <Field name="email" type="email" className="form-control email-input" id="email" placeholder="E-Mail Address" required />
                          {errors.email && touched.email && <label className="error" htmlFor="name">{errors.email}</label>}
                        </div>

                        <div className="donate-detail-form-btn">
                          <button type="submit" id="form-submit" className="main-button">Send</button>
                        </div>

                      </div>

                    </form>

                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Donate

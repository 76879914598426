/* eslint-disable import/no-anonymous-default-export */

export default {

    Logo: require('./logo.png'),
    Mobile: require('./mobile_mockup.png'),
    MobileRight: require('./mobie_mockup_RightView.png'),

    PodCastSlide: require('./podcastlogo1.png'),
    PodCastLogo: require('./podlogo.jpg'),

    Slide1: require('./concertdp2.jpg'),
    Slide2: require('./concertdp.jpg'),
    Slide3: require('./concertdp4.jpg'),
    Slide4: require('./concertdp3.jpg'),
    Slide5: require('./red2.jfif'),
    Slide6: require('./become.jfif'),

    GirlImg: require('./index_pic.png'),
    AboutImg: require('./index_pic2.png'),
    MicImg: require('./Capture2.png'),

    CardImg: require('./playcover.PNG'),
    PlayCardImg1: require('./cov3.jfif'),
    PlayCardImg2: require('./playcover2.PNG'),
    PlayCardImg3: require('./playcover3.PNG'),
    Song: require('./song.mp3'),

    PrayerPointImg: require('./background-outlines.jpg'),

    TestimonyImg: require('./become.jfif'),

    CounsellingImg: require('./coun.jpg'),

    ContactUsImg: require('./contact-us.jpg'),
    
    DonateImg: require('./donate.jpg'),

    DetoxXImg: require('./detox_x_logo.png'),

    DailyVerseSlide: require('./dailydose.jpeg'),
    DailyJokesSlide: require('./jokes1.JPG'),
    DailyDoseSlide: require('./jokes2.JPG'),

    CardIcon: require('./featured-item-01.png'),

    BookIcon: require('./icons8-book-52.png'),
    ErrorLiveImg: require('./errorImage.jpg'),
    
    FacebookPng: require('./Facebook-png.png'),
    InstagramPng: require('./Instagram-png.png'),
    TwitterPng: require('./Twitter-png.png'),

}
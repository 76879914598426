import React from 'react'
import { Scrollbar, A11y, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import Skeleton from 'react-loading-skeleton';

const SwiperLoader = ({ slidesPerView }) => {
    return (
        <>
            <Swiper
                modules={[Scrollbar, A11y, Mousewheel]}
                spaceBetween={20}
                slidesPerView={slidesPerView}
                scrollbar={{ draggable: true }}
                mousewheel={true}
            >
                <SwiperSlide>
                    <div className="blog-post-thumb mx-3">
                        <div className="blog-post-img">
                            <Skeleton />
                        </div>
                        <div className="blog-content blog-loader">
                            <div className='blog-content-title mt-2'><h4><Skeleton /></h4></div>
                            <div className="blog-content-sub-title mt-2"><h6><Skeleton /></h6></div>
                            <Skeleton className='btn-skelton' />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="blog-post-thumb mx-3">
                        <div className="blog-post-img ">
                            <Skeleton />
                        </div>
                        <div className="blog-content blog-loader">
                            <div className='blog-content-title mt-2'><h4><Skeleton /></h4></div>
                            <div className="blog-content-sub-title mt-2"><h6><Skeleton /></h6></div>
                            <Skeleton className='btn-skelton' />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="blog-post-thumb mx-3">
                        <div className="blog-post-img">
                            <Skeleton />
                        </div>
                        <div className="blog-content blog-loader">
                            <div className='blog-content-title mt-2'><h4><Skeleton /></h4></div>
                            <div className="blog-content-sub-title mt-2"><h6><Skeleton /></h6></div>
                            <Skeleton className='btn-skelton' />
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </>
    )
}

export default SwiperLoader

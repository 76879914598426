/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './artistdetail.css'
import { axiosInstance } from '../../axiosConfig';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Images from '../../Images';
import { FaFacebookF, FaMusic, FaShare, FaTwitter } from 'react-icons/fa';
import { ImInstagram } from 'react-icons/im';
import Skeleton from 'react-loading-skeleton';

const ArtistDetail = () => {
    const [links, setLinks] = useState({});
    const location = useLocation();

    useEffect(() => {
        getLinks();
        return () => {
            setLinks({});
        };
    }, []);

    const getLinks = async () => {
        axiosInstance.get(`/setting/detail?type=links`)
            .then((response) => {
                const responseData = response?.data?.data;
                const { status } = response?.data;
                if (status) {
                    setLinks(responseData);
                }
            })
            .catch((error) => toast.error(error?.message));
    };
    return (
        <div className='artist-detail'>
            <div className='container'>
                <div className='artist-detail-content'>
                    <div className='artist-detail-img'>
                        <img
                            src={location?.state?.IMAGE}
                            alt={location?.state?.TITLE}
                            onError={(e) => {
                                e.target.onError = null;
                                e.target.src = Images.ErrorLiveImg;
                            }}
                        />
                    </div>
                    <div className='artist-detail-text mt-3'>
                        <div className='artist-detail-text-title'><h2>{location?.state?.ALBUM}</h2></div>
                        <div className='artist-detail-text-part mt-4'>
                            <h3>{location?.state?.TITLE}</h3>
                            <h6>{location?.state?.ARTIST}</h6>
                            <h6>{location?.state?.YEAR}</h6>
                        </div>
                        <div className='live-section-text-content-icons mt-3'>
                            <ul className="social">
                                <li><a target="_blank" href={links?.facebook} className='instagram'><FaFacebookF className="socialSvg" /></a></li>
                                <li><a target="_blank" href={links?.twitter} className='instagram'><FaTwitter className="socialSvg" /></a></li>
                                <li><a target="_blank" href={links?.instagram} className='instagram'><ImInstagram className="socialSvg" /></a></li>
                                {/* <li><a target="_blank" href={links?.playStore} className='instagram'><FaMusic className="socialSvg" /></a></li> */}
                                <li><a target="_blank" href='/artistdetail' className='instagram'><FaShare className="socialSvg" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistDetail

import React from 'react'
import Images from '../../Images'
import { axiosInstance } from '../../axiosConfig';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { HopeValidationSchema } from '../../validation';


const DetoxX = () => {

  const initialState = {
    name: '',
    phone: '',
    email: '',
    message: '',
    type: 'detox',
  }

  const handleSubmit = async (data, { resetForm }) => {
    axiosInstance.post(`/hope/create`, data)
      .then((response) => {
        const { status, message } = response?.data;
        if (status) {
          resetForm();
          toast.success(message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='testimonies'>
      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
        </div>
      </section>
      <div className='container'>
        <div className='prayer-points-title'>
          <div className='testimonies-form-img detox'>
            <img src={Images.DetoxXImg} alt='' />
          </div>
          <h2 className='color-green mt-4'>Detox X</h2>
          <h6>Detox X is a prophetic soul healing and deliverance session led by the Holy Spirit.</h6>
        </div>
        <div className='prayer-points-form-content'>
          <div className='prayer-points-form'>
            <Formik
              initialValues={initialState}
              onSubmit={handleSubmit}
              validationSchema={HopeValidationSchema}
            >
              {({ errors, values, touched }) => {
                return (
                  <Form id="contact" method="post" noValidate="novalidate">
                    <div className="prayer-points-form-title">
                      <h6>Book a Session</h6>
                    </div>
                    <div className="prayer-points-form-field">
                      <div className='form-field'>
                        <Field name="name" type="text" id='name' value={values.name} className="form-control name-input" placeholder="Full Name" />
                        {errors.name && touched.name && <label className="error" htmlFor="name">{errors.name}</label>}
                      </div>
                      <div className='form-field'>
                        <Field name="phone" type="number" id='phone' className="form-control number-input" placeholder="Mobile Number" />
                        {errors.phone && touched.phone && <label className="error" htmlFor="phone">{errors.phone}</label>}
                      </div>
                      <div className='form-field'>
                        <Field name="email" type="email" id='email' className="form-control email-input" placeholder="Email" />
                        {errors.email && touched.email && <label className="error" htmlFor="email">{errors.email}</label>}
                      </div>
                      <div className='form-field form-field-area'>
                        <Field as='textarea' name="message" id='message' rows={5} className="form-control" placeholder="Additional Information" />
                        {errors.message && touched.message && <label className="error" htmlFor="message">{errors.message}</label>}
                      </div>
                      <div className="prayer-points-form-btn">
                        <button type="submit" id="form-submit" className="main-button bg-green">Send</button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetoxX

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { ImInstagram } from 'react-icons/im'
import { GrApple } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'
import { axiosInstance } from './../../axiosConfig';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const Footer = () => {

    const [links, setLinks] = useState({});

    useEffect(() => {
        getLinks();
        return () => {
            setLinks({});
        };
    }, []);

    const getLinks = async () => {
        axiosInstance.get(`/setting/detail?type=links`)
            .then((response) => {
                const responseData = response?.data?.data;
                const { status } = response?.data;
                if (status) {
                    setLinks(responseData);
                }
            })
            .catch((error) => toast.error(error?.message));
    };

    return (
        <div className='footer'>

            <div className="container">
                <div className="mini-content" id="footeralign">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-3 col-sm-6" id="footeredit">
                            <ul className="list">
                                <li className=""><h3 className="section-title">Music</h3></li>
                                <li className="mt-4"><Link to='/live'>Live</Link></li>
                                {/* <li className=""><Link to='/'>Ambassadors</Link></li> */}
                            </ul><br />
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6  " id="footeredit">
                            <ul className="list">
                                <li className=""><h3 className="section-title">Hope</h3></li>
                                <li className="mt-4"><Link to='/prayer-points'>Prayer Point</Link></li>
                                <li className=""><Link to='/testimonies'>Testimony</Link></li>
                                <li className=""><Link to='/counselling'>Counselling</Link></li>
                                <li className=""><Link to='/detox-x'>Detox-X</Link></li>
                            </ul><br />
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6  " id="footeredit">
                            <ul className="list">
                                <li className=""><h3 className="section-title">Events</h3></li>
                                <li className="mt-4"><Link to='/daily-updates'>Updates</Link></li>
                                <li className=""><Link to='/aboutus'>About Us</Link></li>
                                <li className=""><Link to='/contactus'>Contact Us</Link></li>
                                <li className=""><Link to='/terms-of-service'>Terms of Service</Link></li>  
                            </ul>
                        </div>
                        {/* <div className="col-lg-2 col-md-3 col-sm-6  " id="footeredit">
                            <ul className="list">
                                <li className=""><h3 className="section-title">About Us</h3></li>
                                <li className="mt-4">support</li>
                                <li className="">Careers</li>
                                <li className="">FAQ's</li>
                                <div></div>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="social">
                            <li><a target="_blank" href={links?.facebook} className='facebook'><FaFacebookF className='socialSvg' /></a></li>
                            <li><a target="_blank" href={links?.twitter} className='twitter'><FaTwitter className='socialSvg' /></a></li>
                            <li><a target="_blank" href={links?.instagram} className='instagram'><ImInstagram className='mb-1 socialSvg' /></a></li>
                            <li><a target="_blank" href={links?.playStore} className='googleplay'><FaGooglePlay className='mb-1 socialSvg' /></a></li>
                            <li><a target="_blank" href={links?.appStore} className='applestore'><GrApple className='mb-1 socialSvg' /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p className="copyright">Copyright © 2022 AlphaOne Radio Company - Design: alphaOne Dev</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer

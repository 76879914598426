import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './navbar.css'
import Images from '../../Images';
import Dropdown from '../Dropdown/Dropdown';
import { ThemeContext } from '../ThemeProvider/ThemeProvider';

const hopeList = [
  { link: '/prayer-points', name: 'Prayer Points' },
  { link: '/testimonies', name: 'Testimonies' },
  { link: '/counselling', name: 'Counselling' },
  { link: '/detox-x', name: 'Detox X' },
]

const moreList = [
  { link: '/daily-updates', name: 'Updates' },
  { link: '/aboutus', name: 'About Us' },
  { link: '/contactus', name: 'Contact Us' },
]

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState({
    Hope: false,
    More: false,
  });

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
    setIsOpen({ Hope: false, More: false });
  };


  return (
    <div className='Navbar' data-bs-theme="dark">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link to='/' className="navbar-brand">
            <img src={Images.Logo} alt='Alpha One' />
          </Link>
          <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${collapsed ? '' : 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-5">
              <li className="nav-item mx-4">
                <Link className={`nav-link ${pathname === '/' ? 'activate' : ''}`} onClick={toggleNavbar} to='/'>Home</Link>
              </li>
              <li className="nav-item mx-4">
                <Link className={`nav-link ${pathname === '/live' ? 'activate' : ''}`} onClick={toggleNavbar} to="/live">Live</Link>
              </li>

              <Dropdown
                className={`nav-drodown ${hopeList?.find((li) => pathname === li?.link) ? 'activate' : ''}`}
                title="Hope"
                list={hopeList}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              // isOpen={isOpen.Hope}
              // setIsOpen={setIsOpen}
              />
              <Dropdown
                className={`${moreList?.find((li) => pathname === li?.link) ? 'activate' : ''}`}
                title="More"
                list={moreList}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              // isOpen={isOpen.More}
              // setIsOpen={setIsOpen}
              />
            </ul>

            <Link style={{ textDecoration: 'none' }} to="/donate"><div className='donate-btn' onClick={toggleNavbar}><span>Donate</span></div></Link>

            
          </div>
        </div>
      </nav>

    </div>
  )
}

export default Navbar

import React from 'react'
import './prayerpoint.css'
import Images from '../../Images'
import { Field, Form, Formik } from 'formik'
import { HopeValidationSchema } from '../../validation'
import { axiosInstance } from '../../axiosConfig'
import { toast } from 'react-toastify'
import { GrApple } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'

const PrayerPoints = () => {

  const initialState = {
    name: '',
    phone: '',
    email: '',
    message: '',
    isActive: false,
    type: 'prayer',
  }

  const handleSubmit = async (data, { resetForm }) => {
    axiosInstance.post(`/hope/create`, data)
      .then((response) => {
        const { status, message } = response?.data;
        if (status) {
          resetForm();
          toast.success(message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='prayer-points'>
      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
        </div>
      </section>
      <div className='container'>
        <div className='prayer-points-title'>
          <div className='prayer-points-form-img'>
            <img src={Images.PrayerPointImg} alt='' />
          </div>
          <h2 className='mt-4'>Prayer Request</h2>
          <h6>We believe in the power of God who moves when we pray, let us pray with you today</h6>
        </div>
        <div className='prayer-points-form-content'>
          <div className='prayer-points-form'>
            <Formik
              initialValues={initialState}
              onSubmit={handleSubmit}
              validationSchema={HopeValidationSchema}
            >
              {({ errors, values, touched }) => {
                return (
                  <Form id="contact" method="post" noValidate="novalidate">
                    <div className="prayer-points-form-title">
                      <h6>Prayer Request</h6>
                    </div>
                    <div className="prayer-points-form-field">
                      <div className='form-field'>
                        <Field name="name" type="text" id='name' value={values.name} className="form-control name-input" placeholder="Full Name" />
                        {errors.name && touched.name && <label className="error" htmlFor="name">{errors.name}</label>}
                      </div>
                      <div className='form-field'>
                        <Field name="phone" type="number" id='phone' className="form-control number-input" placeholder="Mobile Number" />
                        {errors.phone && touched.phone && <label className="error" htmlFor="phone">{errors.phone}</label>}
                      </div>
                      <div className='form-field'>
                        <Field name="email" type="email" id='email' className="form-control email-input" placeholder="Email" />
                        {errors.email && touched.email && <label className="error" htmlFor="email">{errors.email}</label>}
                      </div>
                      <div className='form-field form-field-area'>
                        <Field as='textarea' name="message" id='message' rows={5} className="form-control" placeholder="Additional Information" />
                        {errors.message && touched.message && <label className="error" htmlFor="message">{errors.message}</label>}
                      </div>
                      <div className='checkbox-field checkbox-wrapper-33'>
                        <label className="checkbox">
                          <Field type="checkbox" name='isActive' className='me-1 checkbox__trigger visuallyhidden' />
                          <span className="checkbox__symbol">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28" height="28px" width="28px" className="icon-checkbox" aria-hidden="true">
                              <path d="M4 14l8 7L24 7"></path>
                            </svg>
                          </span>
                        </label>
                        <span className='checkbox__textwrapper'>I want to keep my identity confidential in refference to name calling when sharing.</span>
                      </div>
                      <div className="prayer-points-form-btn">
                        <button type="submit" id="form-submit" className="main-button">Send</button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrayerPoints

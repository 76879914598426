import React, { useEffect, useState } from "react";
import "./dailyupdates.css";
import { axiosInstance } from "./../../axiosConfig";
import { toast } from "react-toastify";
import SwiperSlider from "../../components/swiper-slider/SwiperSlider";

const DailyUpdates = () => {
  const [updates, setUpdates] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUpdate();
    return () => {
      setUpdates({});
    };
  }, []);

  const getUpdate = async () => {
    setLoading(true)
    axiosInstance
      .get(`/upload/all`)
      .then((response) => {
        const responseData = response?.data?.data;
        const { status } = response?.data;
        if (status) {
          setUpdates(responseData);
          setLoading(false)
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className="daily-updates">
      <div className="container">
        <div className="daily-verse">
          <div className="daily-verse-title text-center">
            <h2>Daily Updates</h2>
          </div>
          <SwiperSlider copyAndShare={true} items={updates?.updates} loading={loading} />
        </div>
        {/* <div className="daily-verse">
          <div className="daily-verse-title text-center">
            <h2>Daily Verse</h2>
          </div>
          <SwiperSlider copyAndShare={true} items={updates?.verse} />
        </div>
        <div className="daily-jokes">
          <div className="daily-jokes-title text-center">
            <h2>Daily jokes</h2>
          </div>
          <SwiperSlider copyAndShare={true} items={updates?.jokes} />
        </div>
        <div className="daily-dose">
          <div className="daily-dose-title text-center">
            <h2>Daily dose</h2>
          </div>
          <SwiperSlider copyAndShare={true} items={updates?.dose} />
        </div> */}
      </div>
    </div>
  );
};

export default DailyUpdates;

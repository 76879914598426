/* eslint-disable react/jsx-no-target-blank */
import './weeklybuzz.css'
import { Link, useParams } from 'react-router-dom';
import { axiosInstance } from './../../axiosConfig';
import { toast } from 'react-toastify';
import React from 'react';
import Skeleton from 'react-loading-skeleton';


const WeeklyBuzz = () => {
  const { id } = useParams();
  const [event, setEvent] = React.useState({})
  const [tickets, setTickets] = React.useState([])
  const [eventLoader, setEventLoader] = React.useState(false)
  const [ticketLoader, setTicketLoader] = React.useState(false)

  React.useEffect(() => {
    getEventById();
    getTicketById();
    return () => {
      setEvent({});
      setTickets([])
    };
  }, [])

  const getEventById = async () => {
    setEventLoader(true)
    axiosInstance.get(`/upload/web/weekly-buzz/${id}`)
      .then((response) => {
        const responseData = response?.data?.data;
        setEvent(responseData);
        setEventLoader(false)
      })
      .catch((error) => toast.error(error?.message));
  };

  const getTicketById = async () => {
    setTicketLoader(true)
    axiosInstance.get(`/upload/ticket/all/${id}`)
      .then((response) => {
        const responseData = response?.data?.data?.rows;
        setTickets(responseData);
        setTicketLoader(false)
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='weekly-buzz-section'>
      <div className="welcome-area" id="welcome">
        <section className="weeklybuzz-event">
          <div className="container">
            <div className="weeklybuzz-event-content">
              <div className="weeklybuzz-event-text">
                <div className="weeklybuzz-event-text-heading">
                  {eventLoader ? <Skeleton width={400} /> : <h3 className="section-title">{event?.title ? event?.title : ''}</h3>}
                </div>
                <div className="weeklybuzz-event-text-paragraph mt-3">
                  {eventLoader ? <Skeleton /> : (
                    <>
                      <h6>{event?.text ? event?.text : ''}</h6>
                      <Link className='mt-4' to='#'>{event?.link ? event?.link : ''}</Link>
                    </>
                  )}
                </div>
              </div>
              <div className="weeklybuzz-event-img" data-aos="fade-left">
                {eventLoader ? <Skeleton className='img-skeleton' width={300} /> : <img src={event?.image ? event?.image : null} className="" alt="App" />}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className='weeklybuzz-ticket'>
        <div className='weeklybuzz-ticket-content'>
          <div className='container'>
            <div className='weeklybuzz-ticket-title text-center'>
              <h2>Ticket Pricing</h2>
            </div>
            <div className='weeklybuzz-ticket-cards' data-aos="fade-up" >
              {ticketLoader ? (
                <>
                  <div className='weeklybuzz-ticket-card'>
                    <div className='weeklybuzz-ticket-card-title'><Skeleton /></div>
                    <Skeleton className='mt-3' />
                    <div className='weeklybuzz-ticket-card-btn mt-5'>
                      <Skeleton className='ઇbtn-skelton' />
                    </div>
                  </div>
                  <div className='weeklybuzz-ticket-card'>
                    <div className='weeklybuzz-ticket-card-title'><Skeleton /></div>
                    <Skeleton className='mt-3' />
                    <div className='weeklybuzz-ticket-card-btn mt-5'>
                      <Skeleton className='ઇbtn-skelton' />
                    </div>
                  </div>
                  <div className='weeklybuzz-ticket-card'>
                    <div className='weeklybuzz-ticket-card-title'><Skeleton /></div>
                    <Skeleton className='mt-3' />
                    <div className='weeklybuzz-ticket-card-btn mt-5'>
                      <Skeleton className='ઇbtn-skelton' />
                    </div>
                  </div>
                </>
              ) :
                tickets?.map((ticket) => (
                  <div key={ticket?.id} className='weeklybuzz-ticket-card'>
                    <div className='weeklybuzz-ticket-card-title'><h6>{ticket?.name}</h6></div>
                    <div className='weeklybuzz-ticket-card-price'><h3>{`$ ${ticket?.price}`}</h3><h6 className='mt-2'>{ticket?.date}</h6></div>
                    <div className='weeklybuzz-ticket-card-btn mt-5'>
                      <a href={ticket?.link} target='_blank'>
                        <button>purchase now</button>
                      </a>
                    </div>
                  </div>
                ))
              }

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WeeklyBuzz

import * as Yup from 'yup';

export const HopeValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().nullable().required('Name is required!'),
        phone: Yup.number().nullable().required('Mobile Number is required!'),
        email: Yup.string()
            .nullable()
            .matches(new RegExp(
                /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
            ), 'Please enter valid Email!')
            .required('Email is required!'),
        message: Yup.string().nullable().required('Message is required!'),
    })
}
export const SubscribeContactValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().nullable().required('Name is required!'),
        email: Yup.string()
            .nullable()
            .matches(new RegExp(
                /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
            ), 'Please enter valid Email!')
            .required('Email is required!'),
        message: Yup.string().nullable().required('Message is required!'),
    })
}

export const SubscribeDonateValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().nullable().required('Name is required!'),
        email: Yup.string()
            .nullable()
            .matches(new RegExp(
                /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
            ), 'Please enter valid Email!')
            .required('Email is required!'),
    })
}
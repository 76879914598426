import React, { useEffect, useState } from 'react'
import './ambasador.css'
import CountUp from 'react-countup';
import workprocessicon from '../../Images/work-process-item-01.png'
import whatsayicon from '../../Images/testimonial-icon.png'
import whatsayimg from '../../Images/60x60.png'
import blogimg from '../../Images/blog-item-01.PNG'
import Images from '../../Images'
import { useLocation } from 'react-router-dom';

const Ambasador = () => {

    const location = useLocation()

    const [countTrigger, setCountTrigger] = useState(false);

    const handleScroll = () => {
        const scrollValue = window.scrollY;

        if (scrollValue >= 3500 && !countTrigger) {
            setCountTrigger(true)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location])


    return (
        <div className='ambasador'>

            <section className='herosection'>
                <div className='welcome-area'>
                    <div className='container'>
                        <div className='welcome-area-content'>
                            <div className='welcome-area-text'>
                                <h1>We provide the best strategy to grow up your <strong> business</strong> </h1>
                                <h6>Softy Pinko is a professional Bootstrap 4.0 theme designed by Template Mo for your company at absolutely free of charge</h6>
                                <div className='discover-more-btn'>
                                    <button className='btn' >discover more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='welcome-area-cards'>
                <div className='welcome-area-card' data-aos="fade-up" >
                    <div className='welcome-area-card-icon'><img src={Images.CardIcon} alt='' /></div>
                    <div className='welcome-area-card-title'><h5>Modern Strategy</h5></div>
                    <div className='welcome-area-card-subtitle'><h6>Customize anything in this template to fit your website needs</h6></div>
                </div>
                <div className='welcome-area-card' data-aos="fade-up" data-aos-delay="200">
                    <div className='welcome-area-card-icon'><img src={Images.CardIcon} alt='' /></div>
                    <div className='welcome-area-card-title'><h5>Best Relationship</h5></div>
                    <div className='welcome-area-card-subtitle'><h6>Contact us immediately if you have a question in mind</h6></div>
                </div>
                <div className='welcome-area-card' data-aos="fade-up" data-aos-delay="400">
                    <div className='welcome-area-card-icon'><img src={Images.CardIcon} alt='' /></div>
                    <div className='welcome-area-card-title'><h5>Ultimate Marketing</h5></div>
                    <div className='welcome-area-card-subtitle'><h6>You just need to tell your friends about our free templates</h6></div>
                </div>
            </div>

            <section className='about-project'>
                <div className='container'>
                    <div className='about-project-content'>
                        <div className='discuss-project'>
                            <div className='discuss-project-img' data-aos="fade-right">
                                <img src={Images.Mobile} alt='' />
                            </div>
                            <div className='discuss-project-text'>
                                <h4>Let’s discuss about you project</h4>
                                <h6>Nullam sit amet purus libero. Etiam ullamcorper nisl ut augue blandit, at finibus leo efficitur. Nam gravida purus non
                                    sapien auctor, ut aliquam magna ullamcorper.</h6>
                            </div>
                        </div>
                        <hr />
                        <div className='grow-business'>
                            <div className='grow-business-text'>
                                <h4>We can help you to grow your business</h4>
                                <h6>Aenean pretium, ipsum et porttitor auctor, metus ipsum iaculis nisi, a bibendum lectus libero vitae urna. Sed id leo eu dolor
                                    luctus congue sed eget ipsum. Nunc nec luctus libero. Etiam quis dolor elit.</h6>
                            </div>
                            <div className='grow-business-img' data-aos="fade-left">
                                <img src={Images.Mobile} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='work-process'>
                <div className='container'>
                    <div className='work-process-content'>
                        <div className='work-process-title'><h3>work process</h3></div>
                        <div className='work-process-subtitle'>
                            <h6>Aenean nec tempor metus. Maecenas ligula dolor, commodo in imperdiet interdum, vehicula ut ex. Donec ante diam.</h6>
                        </div>
                        <div className='work-process-cards row'>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>get ideas</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>sketch up</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>discuss</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>revise</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>approve</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                            <div className='work-process-card'>
                                <div className='work-process-card-img'><img src={workprocessicon} alt='' /></div>
                                <div className='work-process-card-text'>
                                    <h5>launch</h5>
                                    <h6>Godard pabst prism fam cliche.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='what-do-they-say'>
                <div className='container'>
                    <div className='what-do-they-say-content'>
                        <div className='what-do-they-say-title'><h3>What do they say?</h3></div>
                        <div className='what-do-they-say-subtitle'>
                            <h6>Donec tempus, sem non rutrum imperdiet, lectus orci fringilla nulla, at accumsan elit
                                eros a turpis. Ut sagittis lectus libero.</h6>
                        </div>
                        <div className='what-do-they-say-cards'>
                            <div className='what-do-they-say-card'>
                                <div className='what-do-they-say-card-img'><img src={whatsayicon} alt='' /></div>
                                <div className='what-do-they-say-card-text mt-4'>
                                    <h6>Proin a neque nisi. Nam ipsum nisi, venenatis ut nulla quis, egestas scelerisque orci. Maecenas a finibus odio.</h6>
                                </div>
                                <div className='what-do-they-say-user-panel'>
                                    <div className='what-do-they-say-user-panel-img'><img src={whatsayimg} alt='' /></div>
                                    <div className='what-do-they-say-user-panel-text'>
                                        <h5>Catherine Soft</h5>
                                        <h6>Managing Director</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='what-do-they-say-card'>
                                <div className='what-do-they-say-card-img'><img src={whatsayicon} alt='' /></div>
                                <div className='what-do-they-say-card-text mt-4'>
                                    <h6>Integer molestie aliquam gravida. Nullam nec arcu finibus, imperdiet nulla vitae, placerat nibh. Cras maximus venenatis molestie.</h6>
                                </div>
                                <div className='what-do-they-say-user-panel'>
                                    <div className='what-do-they-say-user-panel-img'><img src={whatsayimg} alt='' /></div>
                                    <div className='what-do-they-say-user-panel-text'>
                                        <h5>Kelvin Wood</h5>
                                        <h6>Digital Marketer</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='what-do-they-say-card'>
                                <div className='what-do-they-say-card-img'><img src={whatsayicon} alt='' /></div>
                                <div className='what-do-they-say-card-text mt-4'>
                                    <h6>Quisque diam odio, maximus ac consectetur eu, auctor non lorem. Cras quis est non ante ultrices molestie. Ut vehicula et diam at aliquam.</h6>
                                </div>

                                <div className='what-do-they-say-user-panel'>
                                    <div className='what-do-they-say-user-panel-img'><img src={whatsayimg} alt='' /></div>
                                    <div className='what-do-they-say-user-panel-text'>
                                        <h5>David Martin</h5>
                                        <h6>Website Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pricing-plan'>
                <div className='pricing-plan-content'>
                    <div className='container'>
                        <div className='weeklybuzz-ticket-title text-center'>
                            <h2>Pricing Plans</h2>
                        </div>
                        <div className='weeklybuzz-ticket-sub-title text-center mt-4'>
                            <h6>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</h6>
                        </div>
                        <div className='weeklybuzz-ticket-cards'>
                            <div className='weeklybuzz-ticket-card' data-aos="fade-up">
                                <div className='weeklybuzz-ticket-card-title'><h6>Starter</h6></div>
                                <div className='weeklybuzz-ticket-card-price'><h3>$ 14.50</h3><h6 className='mt-2'>monthly</h6></div>
                                <div className='weeklybuzz-ticket-card-details mt-5'>
                                    <h6>60 GB space</h6>
                                    <h6>600 GB transfer</h6>
                                    <h6>Pro Design Panel</h6>
                                    <h6 className='text-decoration-line-through'>15-minute support</h6>
                                    <h6 className='text-decoration-line-through'>Unlimited Emails</h6>
                                    <h6 className='text-decoration-line-through'>24/7 Security</h6>
                                </div>
                                <div className='weeklybuzz-ticket-card-btn mt-5'>
                                    <button>purchase now</button></div>
                            </div>
                            <div className='weeklybuzz-ticket-card' data-aos="fade-up" data-aos-delay="200">
                                <div className='weeklybuzz-ticket-card-title'><h6>Premium</h6></div>
                                <div className='weeklybuzz-ticket-card-price'><h3>$ 21.50</h3><h6 className='mt-2'>monthly</h6></div>
                                <div className='weeklybuzz-ticket-card-details mt-5'>
                                    <h6>120 GB space</h6>
                                    <h6>1200 GB transfer</h6>
                                    <h6>Pro Design Panel</h6>
                                    <h6>15-minute support</h6>
                                    <h6 className='text-decoration-line-through'>Unlimited Emails</h6>
                                    <h6 className='text-decoration-line-through'>24/7 Security</h6>
                                </div>
                                <div className='weeklybuzz-ticket-card-btn mt-5'>
                                    <button>purchase now</button></div>
                            </div>
                            <div className='weeklybuzz-ticket-card' data-aos="fade-up" data-aos-delay="400">
                                <div className='weeklybuzz-ticket-card-title'><h6>Advance</h6></div>
                                <div className='weeklybuzz-ticket-card-price'><h3>$ 42.00</h3><h6 className='mt-2'>monthly</h6></div>
                                <div className='weeklybuzz-ticket-card-details mt-5'>
                                    <h6>60 GB space</h6>
                                    <h6>600 GB transfer</h6>
                                    <h6>Pro Design Panel</h6>
                                    <h6>15-minute support</h6>
                                    <h6>Unlimited Emails</h6>
                                    <h6>24/7 Security</h6>
                                </div>
                                <div className='weeklybuzz-ticket-card-btn mt-5'>
                                    <button>purchase now</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='ambasador-counter'>
                <div className='weeklybuzz-counter-bg'>
                    <div className='container'>
                        <div className='weeklybuzz-counter-content'>
                            <div className='weeklybuzz-counter-part'>
                                <div className='weeklybuzz-counter-count'>
                                    <h2><CountUp end={countTrigger ? 126 : 0} duration={3} /></h2>
                                </div>
                                <div className='weeklybuzz-counter-title mt-3'>
                                    <h6>Projects</h6>
                                </div>
                            </div>
                            <div className='weeklybuzz-counter-part'>
                                <div className='weeklybuzz-counter-count'>
                                    <h2><CountUp end={countTrigger ? 63 : 0} duration={3} /></h2>
                                </div>
                                <div className='weeklybuzz-counter-title mt-3'>
                                    <h6>Happy Clients</h6>
                                </div>
                            </div>
                            <div className='weeklybuzz-counter-part'>
                                <div className='weeklybuzz-counter-count'>
                                    <h2><CountUp end={countTrigger ? 18 : 0} duration={3} /></h2>

                                </div>
                                <div className='weeklybuzz-counter-title mt-3'>
                                    <h6>Awards Wins</h6>
                                </div>
                            </div>
                            <div className='weeklybuzz-counter-part'>
                                <div className='weeklybuzz-counter-count'>
                                    <h2><CountUp end={countTrigger ? 27 : 0} duration={3} /></h2>
                                </div>
                                <div className='weeklybuzz-counter-title mt-3'>
                                    <h6>Countries</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='blog-entries'>
                <div className='container'>
                    <div className='blog-entries-content'>
                        <div className='blog-entries-title'><h3>Blog Entries</h3></div>
                        <div className='blog-entries-subtitle'>
                            <h6>Integer molestie aliquam gravida. Nullam nec arcu finibus, imperdiet nulla vitae, placerat nibh. Cras maximus venenatis
                                molestie.</h6>
                        </div>
                        <div className='blog-entries-cards'>
                            <div className='blog-entries-card'>
                                <div className='blog-entries-card-img'><img src={blogimg} alt='' /></div>
                                <div className='blog-entries-card-text mt-5'>
                                    <h5>Vivamus ac vehicula dui</h5>
                                    <h6>Cras aliquet ligula dui, vitae fermentum velit tincidunt id. Praesent eu finibus nunc. Nulla in sagittis eros.
                                        Aliquam egestas augue.</h6>
                                </div>
                                <div className='blog-entries-card-btn mt-5'>
                                    <button className='btn'>read more</button>
                                </div>
                            </div>
                            <div className='blog-entries-card'>
                                <div className='blog-entries-card-img'><img src={blogimg} alt='' /></div>
                                <div className='blog-entries-card-text mt-5'>
                                    <h5>Phasellus convallis augue</h5>
                                    <h6>Aliquam commodo ornare nisl, et scelerisque nisl dignissim ac. Vestibulum finibus urna ut velit venenatis, vel ultrices sapien mattis.</h6>
                                </div>
                                <div className='blog-entries-card-btn mt-5'>
                                    <button className='btn'>read more</button>
                                </div>
                            </div>
                            <div className='blog-entries-card'>
                                <div className='blog-entries-card-img'><img src={blogimg} alt='' /></div>
                                <div className='blog-entries-card-text mt-5'>
                                    <h5>Nam gravida purus non</h5>
                                    <h6>Maecenas eu erat vitae dui convallis consequat vel gravida nulla. Vestibulum finibus euismod odio, ut tempus enim varius eu.</h6>
                                </div>
                                <div className='blog-entries-card-btn mt-5'>
                                    <button className='btn'>read more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='ambasador-talk-to-us'>
                <div className='contact-us-content'>
                    <div className='container'>
                        <div className='contact-us-title text-center'><h2 className='text-transform-capitalize'>talk to us</h2></div>
                        <div className='contact-us-sub-title text-center mt-3'><h6>Maecenas pellentesque ante faucibus lectus vulputate sollicitudin. Cras feugiat hendrerit semper.</h6></div>
                        <div className='contact-us-detail'>
                            <div className='contact-us-contactdetail'>
                                <div className='contact-us-contactdetail-title'><h4>Keep in touch</h4></div>
                                <div className='contact-us-mail mt-4'>
                                    <h6 className='mt-3'>110-220 Quisque diam odio, maximus ac consectetur eu, 10260
                                        auctor non lorem </h6>
                                </div>
                                <div className='contact-us-number mt-5'>
                                    <h6 className='mt-3'>You are NOT allowed to re-distribute Softy Pinko template on any template collection websites. Thank you.</h6>
                                </div>
                            </div>
                            <div className='contact-us-detail-form'>
                                <form id="contact" method="get">
                                    <div className="contact-us-detail-form-field">
                                        <input name="name" type="text" className="form-control name-input" id="name" placeholder="Full Name" required />
                                        <input name="email" type="text" className="form-control email-input" id="email" placeholder="Email Address" required />
                                        <textarea name="message" rows={5} className="form-control" id="message" placeholder="Your message" required defaultValue={""} />
                                        <div className="contact-us-detail-form-btn">
                                            <button type="submit" id="form-submit" className="main-button">Send message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Ambasador

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './contactus.css'
import { axiosInstance } from './../../axiosConfig';
import { toast } from 'react-toastify';
import { Field, Formik } from 'formik';
import { SubscribeContactValidationSchema } from '../../validation';
import Images from '../../Images';

const ContactUs = () => {

  const [links, setLinks] = useState({});

  useEffect(() => {
    getLinks();
    return () => {
      setLinks({});
    };
  }, []);

  const getLinks = async () => {
    axiosInstance.get(`/setting/detail?type=links`)
      .then((response) => {
        const responseData = response?.data?.data;
        const { status } = response?.data;
        if (status) {
          setLinks(responseData);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  const initialState = {
    name: '',
    email: '',
    message: '',
    type: 'contact',
  }

  const handleSubmit = async (data, { resetForm }) => {
    axiosInstance.post(`/subscribe`, data)
      .then((response) => {
        const { status, message } = response?.data;
        if (status) {
          resetForm();
          toast.success(message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='contact-us'>
      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
        </div>
      </section>
      <div className='contact-us-content'>
        <div className='container'>
          <div className='testimonies-form-img'>
            <img src={Images.ContactUsImg} alt='' />
          </div>
          <div className='contact-us-title text-center mt-4'><h2 className='text-transform-capitalize'>talk to us</h2></div>
          <div className='contact-us-sub-title text-center mt-3'><h6>For all other enquiries, please contact s using various means below</h6></div>
          <div className='contact-us-contactdetail mt-4'>
            <div className='contact-us-mail contact-us-detail'>
              <h5>mail us</h5>
              <h6 className='mt-3'><a href={`mailto:${links?.email}`}><i className="fa fa-envelope me-1" />{ links?.email}</a></h6>
            </div>
            <div className='contact-us-number contact-us-detail'>
              <h5>call us</h5>
              <h6 className='mt-3'><a href={`tel:${links?.sms}`}><i className="fa fa-phone me-1" />{ links?.sms}</a></h6>
            </div>
          </div>
          <div className='contact-us-detail-form'>
            <Formik
              initialValues={initialState}
              onSubmit={handleSubmit}
              validationSchema={SubscribeContactValidationSchema}
            >
              {({ errors, touched, handleSubmit }) => {
                return (

                  <form onSubmit={handleSubmit} id="contact" method="post" noValidate="novalidate">

                    <div className="contact-us-detail-form-field">

                      <div className='form-field'>
                        <Field name="name" type="text" className="form-control name-input" id="name" placeholder="Full Name" required />
                        {errors.name && touched.name && <label className="error" htmlFor="name">{errors.name}</label>}
                      </div>

                      <div className='form-field'>
                        <Field name="email" type="email" className="form-control email-input" id="email" placeholder="E-Mail Address" required />
                        {errors.email && touched.email && <label className="error" htmlFor="name">{errors.email}</label>}
                      </div>

                      <div className='form-field-area'>
                        <Field name="message" as='textarea' rows={5} className="form-control" id="message" placeholder="Your message" required />
                        {errors.message && touched.message && <label className="error" htmlFor="name">{errors.message}</label>}
                      </div>

                      <div className="contact-us-detail-form-btn">
                        <button type="submit" id="form-submit" className="main-button">Send</button>
                      </div>

                    </div>

                  </form>

                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs

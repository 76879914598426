import React, { useEffect, useState } from 'react'
import './podcast.css'
import { GrPlayFill } from 'react-icons/gr'
import Images from '../../Images'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../axiosConfig'
import ReactAudioPlayer from 'react-audio-player'
import AudioPlayer from './AudioPlayer'
import Skeleton from 'react-loading-skeleton'

const Podcast = () => {
    const { id } = useParams();

    const [podcast, setPodcast] = useState(null);
    const [episodes, setEpisodes] = useState([]);
    const [podcastLoader, setPodcastLoader] = useState(false);
    const [episodeLoader, setEpisodeLoader] = useState(false);
    const { pathname } = useLocation()

    useEffect(() => {
        getPodcast();
        getEpisode();
        return () => {
            setPodcast([]);
            setEpisodes([]);
        };
    }, []);

    const getPodcast = async () => {
        setPodcastLoader(true)
        axiosInstance.get(`/podcast/web/getById/${id}`)
            .then((response) => {
                const responseData = response?.data?.data;
                setPodcast(responseData);
                setPodcastLoader(false)
            })
            .catch((error) => toast.error(error?.message));
    };

    const getEpisode = async () => {
        setEpisodeLoader(true)
        axiosInstance.get(`/podcast/web/podcast-episode/${id}`)
            .then((response) => {
                const responseData = response?.data?.data?.rows;
                const mapResponseToColumns = (res, index) => {
                    return {
                        index: index,
                        id: res?.id ? res?.id : null,
                        title: res.title ? res.title : "",
                        subtitle: res.text ? res.text : "",
                        audio: res.audio ? res.audio : "",
                    };
                };
                const customResponse = responseData.map(mapResponseToColumns);
                setEpisodes(customResponse);
                setEpisodeLoader(false)
            })
            .catch((error) => toast.error(error?.message));
    };


    // useEffect(() => {
    //     const modalBackdrop = document.querySelectorAll('.modal-backdrop');
    //     console.log(modalBackdrop);
    //     if (modalBackdrop) {
    //         // modalBackdrop.classList.remove('modalBackdrop');
    //     }
    // }, [pathname])

    // useEffect(() => {
    //     const handlePageHide = () => {
    //         const backdrop = document.querySelector('.modal-backdrop');
    //         const modal = document.querySelector('.modal.show');
    //         console.log(modal);
    //         if (modal) {
    //             modal.classList.remove('show');
    //             if (backdrop) {
    //                 backdrop.remove();
    //             }
    //         }
    //     };
    //     handlePageHide()
    //     // window.addEventListener('pagehide', handlePageHide);

    //     return () => {
    //         window.removeEventListener('pagehide', handlePageHide);
    //     };
    // }, []);

    return (
        <div className='podcast'>

            <section className='alphaone-radio'>
                <div className='container'>
                    <div className='alphaone-radio-content'>
                        <div className='alphaone-radio-text'>
                            <h1>Alpha One Radio</h1>
                            {podcastLoader ? <Skeleton /> : <h6>{podcast?.text}</h6>}
                        </div>
                        <div className='alphaone-radio-img'> {podcastLoader ? <Skeleton className='img-skeleton' /> : <img src={podcast?.image} alt='' />}</div>
                    </div>
                </div>
            </section>

            <section className='station-podcast'>
                <div className='container'>
                    <div className='station-podcast-content text-center'>
                        <div className='station-podcast-title'><h2>{podcast?.title}</h2></div>
                        {/* <div className='station-podcast-subtitle mt-3'><h6>.....................................</h6></div> */}

                        <div className='station-podcast-cards'>
                            {episodeLoader ? (
                                <>
                                    <div className='station-podcast-card'>
                                        <div className='station-podcast-card-text mt-3'>
                                            <h5><Skeleton /></h5>
                                            <h6><Skeleton /></h6>
                                        </div>
                                        <div className='station-podcast-card-btn mt-4'>
                                            <Skeleton className='.btn-skelton' />
                                        </div>
                                    </div>
                                    <div className='station-podcast-card'>
                                        <div className='station-podcast-card-text mt-3'>
                                            <h5><Skeleton /></h5>
                                            <h6><Skeleton /></h6>
                                        </div>
                                        <div className='station-podcast-card-btn mt-4'>
                                            <Skeleton className='.btn-skelton' />
                                        </div>
                                    </div>
                                    <div className='station-podcast-card'>
                                        <div className='station-podcast-card-text mt-3'>
                                            <h5><Skeleton /></h5>
                                            <h6><Skeleton /></h6>
                                        </div>
                                        <div className='station-podcast-card-btn mt-4'>
                                            <Skeleton className='.btn-skelton' />
                                        </div>
                                    </div>

                                </>
                            ) : (
                                episodes?.map((episode) => (
                                    <div className='station-podcast-card' key={episode?.id}>
                                        <div className='station-podcast-card-text mt-3'>
                                            <h5>{episode?.title}</h5>
                                            <h6>{episode?.text}</h6>
                                        </div>
                                        <div className='station-podcast-card-btn mt-4'>
                                            <button className='btn' data-bs-toggle="modal" data-bs-target={`#exampleModal${episode?.id}`}><GrPlayFill className='play-icon me-1 mb-1' /><span>listen</span></button>
                                        </div>
                                        <div className="modal fade" id={`exampleModal${episode?.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <AudioPlayer episodes={episodes} index={episode?.index} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))
                            }

                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Podcast

import React, { useEffect, useRef, useState } from 'react'
import './live.css'
import { axiosInstance } from '../../axiosConfig';
import { toast } from 'react-toastify';
import { FaFacebookF, FaMusic, FaPlay, FaShare, FaStop, FaTwitter } from "react-icons/fa";
import Images from '../../Images';
import { ImInstagram } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const Live = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const [links, setLinks] = useState({});
  const [playSong, setPlaySong] = useState(null);
  const [recentlyPlayed, setRecentlyPlayed] = useState([]);
  const [recentlyPlayedLoader, setRecentlyPlayedLoader] = useState([]);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    getLinks();
    getLastPlayed();

    const LastPlayedId = setInterval(getLastPlayed, 12000);

    return () => {
      clearInterval(LastPlayedId);
    };
  }, []);


  const getLastPlayed = async () => {
    setRecentlyPlayedLoader(true)
    await axiosInstance.get(`/radio/getRecentlyPlayedSongs?flag=${true}`)
      .then((response) => {
        const responseData = response?.data?.data;
        const CustomData = responseData.shift()
        const { status } = response?.data;
        if (status) {
          setPlaySong(CustomData);
          const data = responseData.slice(0, 5)
          setRecentlyPlayed(data);
          setRecentlyPlayedLoader(false)
        }
      })
      .catch((error) => {
        toast.error(error?.message)
      })
  };

  const getLinks = async () => {
    axiosInstance.get(`/setting/detail?type=links`)
      .then((response) => {
        const responseData = response?.data?.data;
        const { status } = response?.data;
        if (status) {
          setLinks(responseData);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  const onNavigateArtistDetails = (id) => {
    const songData = recentlyPlayed.find((song) => song.id === id)
    navigate(`/artistdetail/${id}`, { state: songData })
  }


  return (
    <div className='live'>

      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
          <div className='container'>
            <div className='live-section-content'>
              <div className='live-section-card'>
                <div className='live-section-card-img'>
                  {playSong?.IMAGE ? (
                    <img
                      src={playSong?.IMAGE}
                      alt={playSong?.Title}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = Images.ErrorLiveImg;
                      }}
                    />
                  ) : <div className='alt-img'><img src={Images.ErrorLiveImg} alt='' /></div>}
                </div>
              </div>
              <div className='live-section-text-content'>

                <div className='live-section-text-content-title mt-1'>
                  <h1>{playSong?.TITLE ? playSong?.TITLE : 'Worship'}</h1>
                  <h5 className='mt-1' style={{ color: 'white' }}>{playSong?.ARTIST ? playSong?.ARTIST : 'Music'}</h5>
                </div>

                <div className='live-section-text-content-icons'>
                  <div className='live-section-text-content-audio' onClick={togglePlay}>
                    <audio ref={audioRef} src={process.env.REACT_APP_STREAMING_URL} />
                    {isPlaying ? <FaStop /> : <FaPlay className='ms-2' />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-img'>
          {playSong?.IMAGE ? (
            <img
              src={playSong?.IMAGE}
              alt={playSong?.Title}
              onError={(e) => {
                e.target.onError = null;
                e.target.src = Images.ErrorLiveImg;
              }}
            />
          ) : <div className='bg-alt-img'><img src={Images.ErrorLiveImg} alt='' /></div>}
        </div>
        <div className='bg-overlay'></div>
      </section>

      <section className='recently-played'>
        <div className='recently-played-title'>
          <h2>Recently Played</h2>
        </div>
        <div className='recently-played-cards'>
          {
            recentlyPlayedLoader ?
              (
                <>
                  <div className='recently-played-card' >
                    <div className='recently-played-card-img'>
                      <Skeleton />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5><Skeleton /></h5>
                      <h6><Skeleton /></h6>
                    </div>
                  </div>
                  <div className='recently-played-card' >
                    <div className='recently-played-card-img'>
                      <Skeleton />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5><Skeleton /></h5>
                      <h6><Skeleton /></h6>
                    </div>
                  </div>
                  <div className='recently-played-card' >
                    <div className='recently-played-card-img'>
                      <Skeleton />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5><Skeleton /></h5>
                      <h6><Skeleton /></h6>
                    </div>
                  </div>
                  <div className='recently-played-card' >
                    <div className='recently-played-card-img'>
                      <Skeleton />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5><Skeleton /></h5>
                      <h6><Skeleton /></h6>
                    </div>
                  </div>
                  <div className='recently-played-card' >
                    <div className='recently-played-card-img'>
                      <Skeleton />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5><Skeleton /></h5>
                      <h6><Skeleton /></h6>
                    </div>
                  </div>
                </>
              ) :
              recentlyPlayed?.map((list) => {
                return (
                  <div
                    key={list?.id}
                    className='recently-played-card'
                    onClick={() => onNavigateArtistDetails(list?.id)}
                  >
                    <div className='recently-played-card-img'>
                      <img
                        src={list?.IMAGE}
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = Images.ErrorLiveImg;
                        }}
                        alt=''
                      />
                    </div>
                    <div className='recently-played-card-content'>
                      <h5>{list?.TITLE}</h5>
                      <h6>{list?.ARTIST}</h6>
                    </div>
                  </div>
                )
              }
              )
          }
        </div>
      </section>
    </div>
  )
}

export default Live

import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Live from "./pages/Live/Live";
import PrayerPoints from "./pages/PrayerPoints/PrayerPoints";
import Testimonies from "./pages/Testimonies/Testimonies";
import Counselling from "./pages/Counselling/Counselling";
import DailyUpdates from "./pages/DailyUpdates/DailyUpdates";
import WeeklyBuzz from "./pages/WeeklyBuzz/WeeklyBuzz";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Donate from "./pages/Donate/Donate";
import Footer from "./components/Footer/Footer";
import ArtistDetail from "./pages/ArtistDetail/ArtistDetail";
import Ambasador from "./pages/Ambasador/Ambasador";
import Podcast from "./pages/Podcast/Podcast";
import Ambassador from "./pages/Ambassador/Ambassador";
import AOS from "aos";
import "aos/dist/aos.css";
import DetoxX from "./pages/Detox-X/DetoxX";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import { ThemeContext } from "./components/ThemeProvider/ThemeProvider";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

    AOS.init({
      easing: "ease-in-sine",
      once: true,
      offset: 200,
      duration: 500,
    });

    return () => {
      const divToRemove = document.querySelector('.modal-backdrop');
      const divStyle = document.querySelector('.modal-open');
      if (divToRemove) {
        divToRemove.remove();
        divStyle.removeAttribute('style')
        divStyle.removeAttribute('class')
      }
    };
  }, [pathname]);


  const { theme, toggleTheme } = React.useContext(ThemeContext);

  React.useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);


  return (
    <div className="App relative">
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/live" element={<Live />} />
        <Route path="/prayer-points" element={<PrayerPoints />} />
        <Route path="/testimonies" element={<Testimonies />} />
        <Route path="/counselling" element={<Counselling />} />
        <Route path="/detox-x" element={<DetoxX />} />
        <Route path="/daily-updates" element={<DailyUpdates />} />
        <Route path="/event/:id?" element={<WeeklyBuzz />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/artistdetail/:id?" element={<ArtistDetail />} />
        <Route path="/ambasador" element={<Ambasador />} />
        <Route path="/ambassador" element={<Ambassador />} />
        <Route path="/podcast/:id?" element={<Podcast />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
      <Footer />

      {/* <div className="dark-theme-btn">
        <label htmlFor="switch" className="toggle ms-2" >
          <input type="checkbox" className="input" id="switch" onClick={toggleTheme} />
          <div className="icon icon--moon" title='Turn Dark mode'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={32} height={32}>
              <path fillRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="icon icon--sun" title='Turn Light mode'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={32} height={32}>
              <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z" />
            </svg>
          </div>
        </label>
      </div> */}
    </div>
  );
}

export default App;

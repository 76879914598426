/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Scrollbar, A11y, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import { FaShare } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import Images from '../../Images';
import copy from 'clipboard-copy';
import clipboardCopy from 'clipboard-copy';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import SwiperLoader from './SwiperLoader';

export const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
        return description;
    }

    return `${description.substring(0, maxLength)}...`;
}

const SwiperSlider = ({ copyAndShare, items, loading }) => {
    const [slidePerView, setSlidePerView] = useState(3);
    const [shareData, setShareData] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    // console.log(loading);

    const handleCopyClick = async (textToCopy) => {
        try {
            await clipboardCopy(textToCopy);
            setIsCopied(true);
        } catch (err) {
            console.error('Failed to copy to clipboard', err);
        }

        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const shareUrl = 'https://your-website.com'; // Replace with your actual website URL

    const handleFacebookShare = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData?.image)}`;
        window.open(facebookShareUrl, '_blank');
    };

    const handleTwitterShare = () => {
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareData?.text)}&url=${encodeURIComponent(shareUrl)}`;
        window.open(twitterShareUrl, '_blank');
    };

    const handleInstagramShare = () => {
        const instagramShareUrl = `https://www.instagram.com/`;
        window.open(instagramShareUrl, '_blank');
    };


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 991) {
                setSlidePerView(3);
            } else if (window.innerWidth >= 767) {
                setSlidePerView(2);
            } else {
                setSlidePerView(1);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {
                loading ? <SwiperLoader  slidesPerView={slidePerView}/> :

                    <Swiper
                        modules={[Scrollbar, A11y, Mousewheel]}
                        spaceBetween={20}
                        slidesPerView={slidePerView}
                        scrollbar={{ draggable: true }}
                        mousewheel={true}
                    >
                        {items?.length > 0 && (items?.map((item, index) => (
                            <SwiperSlide key={item?.id ? item?.id : index}>
                                <div className="blog-post-thumb mx-3">
                                    <div className="blog-post-img">
                                        <img src={item?.image ? item?.image : ''} alt='' />
                                    </div>
                                    <div className="blog-content">
                                        {!copyAndShare && <div className='blog-content-title mt-2'><h4>{item?.title ? item?.title : ''}</h4></div>}
                                        {!copyAndShare && <div className="blog-content-sub-title mt-2"><h6>{item?.subtitle ? item?.subtitle : ''}</h6></div>}
                                        {
                                            copyAndShare ? (
                                                <div className='d-flex flex-column'>
                                                    <div className="blog-content-text mt-2">
                                                        <h6>{item?.text ? truncateDescription(item?.text, 200) : ''}</h6>
                                                    </div>
                                                    <div>
                                                        <div className="btn main-button mt-4 mx-2" onClick={() => copy(item?.text)}><span><img src={Images.BookIcon} alt="book" className='book-icon mb-1 me-1' />Copy</span></div>
                                                        <div className="btn main-button mt-4 mx-2" data-bs-toggle="modal" data-bs-target='#exampleModal' onClick={() => setShareData(item)}><FaShare className='mb-1 me-1' /><span>Share</span></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Link style={{ textDecoration: 'none' }} to={item?.link ? item?.link : '/'}><div className="btn more-btn mt-4"><div className='donate-btn'><span>More</span></div></div></Link>
                                            )
                                        }
                                    </div>
                                </div>
                            </SwiperSlide>
                        )))}
                    </Swiper>
            }
            <div className="modal share-modal fade" id='exampleModal' tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='d-flex gap-3 justify-content-center'>
                            <div className='model-icons'>
                                <img onClick={handleFacebookShare} src={Images?.FacebookPng} alt='Facebook' />
                            </div>
                            <div className='model-icons'>
                                <img onClick={handleTwitterShare} src={Images?.TwitterPng} alt='Twitter' />
                            </div>
                            <div className='model-icons'>
                                <img onClick={handleInstagramShare} src={Images?.InstagramPng} alt='Instagram' />
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="copy-url-field">
                                <div className="form-feild">
                                    <input type="url" value={shareData?.image ?? ''} disabled className="form-control name-input" />
                                </div>
                                <div className="btn main-button" onClick={() => handleCopyClick(shareData?.image)}>
                                    <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SwiperSlider

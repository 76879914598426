import React from "react";

const TermsOfService = () => {
  return (
    <section className="container terms-service" style={{ padding: "120px 0px 100px" }}>
      <h1 className="mb-4 text-center">Terms Of Service</h1>
      <p className="text-center mb-5">
        Alpha One Foundation (“<b>AOF</b>”) is pleased to provide to you its sites,
        software, applications, content, products, and services (“<b>ALPHA1 </b>
        Services” including and not limited to radio, podcast, music, shows,
        concerts, events, application, website, media, record label and
        broadcast). These terms and conditions apply to your use of the <b>ALPHA1 </b>
        Services (“Terms”). <b>YOU SHOULD CAREFULLY READ THESE TERMS BEFORE USING
          THE <b>ALPHA1 </b> SERVICES.</b>
      </p>
      <div className="mb-5">
        <h3 className="mb-3">Contract between You and Us</h3>
        <p>
          This is a contract between you and AOF. These Terms describe the limited
          basis on which the <b>ALPHA1 </b> Services are available and supersede prior
          agreements or arrangements. Your use of the <b>ALPHA1 </b> Services constitutes
          acceptance of these terms.
        </p>
        <p>
          Supplemental terms and conditions may apply to some <b>ALPHA1 </b> Services,
          such as rules for a particular competition, service, or other activity.
          Any supplemental terms and conditions are in addition to these Terms,
          and, in the event of a conflict, the supplemental terms will control.
        </p>
        <p>
          We may amend these Terms at any time. An amendment will be effective
          thirty (30) days following posting of the amendment on the <b>ALPHA1 </b>
          services(website or app). Continued use of the <b>ALPHA1 </b> Services after
          amendment of the Terms constitutes acceptance of the Amended Terms.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">The ALPHA1 Services </h3>
        <p>
          The <b>ALPHA1 </b> Services are for your personal, noncommercial use and are
          intended for informational and entertainment purposes only. We may add
          to, subtract from or eliminate any part of the <b>ALPHA1 </b> Services, and we
          may terminate an individual’s use of the <b>ALPHA1 </b> Services at any time for
          any reason or for no reason at all.
        </p>
        <p>
          The <b>ALPHA1 </b> Services are copyrighted and subject to protection under
          applicable intellectual property laws including laws applicable to
          copyright, trademarks and service marks. No element of the <b>ALPHA1 </b>
          Services may be used or exploited in any way other than as part of the
          <b>ALPHA1 </b> Services offered to you. You may own the physical media on which
          elements of the <b>ALPHA1 </b> Services are delivered to you, but we retain full
          and complete ownership of the <b>ALPHA1 </b> Services. We do not transfer title
          to any portion of the <b>ALPHA1 </b> Services to you.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Content and License </h3>
        <p>
          If an <b>ALPHA1 </b> Service is configured to enable the use of software,
          content, virtual items or other materials owned or licensed by us, we
          grant you a limited, non-exclusive, non- sublicensable, non-transferable
          license to access and use such software, content, virtual item or other
          material for your personal, noncommercial use only.
        </p>
        <p>
          You agree not to knowingly or recklessly introduce a virus or other
          harmful component, or otherwise tamper with, impair or damage any <b>ALPHA1 </b>
          Service or connected network, or interfere with any person or entity’s
          use or enjoyment of any <b>ALPHA1 </b> Service.
        </p>
        <p>
          You may not circumvent or disable any content protection system or
          digital rights management technology used with any <b>ALPHA1 </b> Service;
          decompile, reverse engineer, disassemble or otherwise reduce any <b>ALPHA1 </b>
          Service to a human-readable form; remove identification, copyright or
          other proprietary notices; or access or use any <b>ALPHA1 </b> Service in an
          unlawful or unauthorized manner. By acquiring services, content or
          software through the <b>ALPHA1 </b> Services, you represent and warrant that
          your access to and use of the services, content or software will comply
          with those requirements.
        </p>
        <p>
          If you register with any <b>ALPHA1 </b> Service, the username and password you
          receive upon registration is an individual, nontransferable license to
          utilize the <b>ALPHA1 </b> Service. Keep this information private and do not
          allow others to access the <b>ALPHA1 </b> Service under your name or in
          violation of these Terms. AOF reserves the right at all times to monitor
          the use of the <b>ALPHA1 </b> Service to ensure compliance with these Terms and
          also reserves the right to refuse registration to any individual or
          entity for any reason or for no reason.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">User Generated Content </h3>
        <p>
          Certain of the <b>ALPHA1 </b> Services may allow you to submit content (“User
          Generated Content”) which may be accessible and viewable by the public.
          Access to these features may be subject to age restrictions. You may not
          submit or upload User Generated Content that is defamatory, harassing,
          threatening, bigoted, hateful, violent, vulgar, obscene, pornographic,
          or otherwise offensive or that harms or can reasonably be expected to
          harm any person or entity, whether or not such material is protected by
          law.
        </p>
        <p>
          We do not claim ownership to your User Generated Content; however, you
          grant us a non- exclusive, sublicensable, irrevocable and royalty-free
          worldwide perpetual license to use, reproduce, transmit, print, publish,
          publicly display, exhibit, distribute, redistribute, copy, index,
          comment on, modify, adapt, translate, create derivative works based
          upon, publicly perform, make available and otherwise exploit such User
          Generated Content, in whole or in part, in all media formats and
          channels now known or hereafter devised (including in connection with
          the <b>ALPHA1 </b> Services and on third-party sites and platforms such as
          Facebook, YouTube and Twitter), in any number of copies and without
          limit as to the manner and frequency of use, without further notice to
          you, with or without attribution, and without the requirement of
          permission from or payment to you or any other person or entity.
        </p>
        <p>
          You represent and warrant that your User Generated Content conforms to
          these Terms and that you own or have the necessary rights and
          permissions, without the need for payment to any other person or entity,
          to use and exploit, and to authorize us to use and exploit, your User
          Generated Content in all manners contemplated by these Terms. You agree
          to indemnify and hold AOF, our subsidiary and affiliated companies, and
          each of their respective employees and officers, harmless from any
          demands, loss, liability, claims or expenses (including attorneys’
          fees), made against us by any third party arising out of or in
          connection with our use and exploitation of your User Generated Content.
          You also agree not to enforce any moral rights, ancillary rights or
          similar rights in or to the User Generated Content against us or our
          licensees, distributors, agents, representatives and other authorized
          users, and agree to procure the same agreement not to enforce from
          others who may possess such rights.
        </p>
        <p>
          To the extent that we authorize you to create, post, upload, distribute,
          publicly display or publicly perform User Generated Content that
          requires the use of our copyrighted works, we grant you a non-exclusive
          license to create a derivative work using our copyrighted works to the
          extent required for the purpose of creating the materials, provided that
          such license shall be conditioned upon your assignment to us of all
          rights in the work you create. If such rights are not assigned to us,
          your license to create derivative works using our copyrighted works
          shall be null and void.
        </p>
        <p>
          We have the right but not the obligation to monitor, screen, post,
          remove, modify, store and review User Generated Content or
          communications sent through a <b>ALPHA1 </b> Service, at any time without notice
          to you and for any reason, including to ensure that the User Generated
          Content or communication conforms to these Terms. We do not endorse the
          opinions, views, advice, or recommendations that might be contained in
          User Generated Content.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Disclaimer of Warranty </h3>
        <p>
          THE SERVICES AND MATERIALS ON THE SITE ARE PROVIDED ON AN "AS IS" AND
          "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
          IMPLIED.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Limitation of Liability </h3>
        <p>
          NEITHER AOF NOR ITS AFFILIATES, OFFICERS, DIRECTORS OR EMPLOYEES, SHALL
          BE LIABLE FOR INJURY, LOSS OR DAMAGE OF ANY KIND RESULTING IN ANY WAY
          FROM THE USE OF (AOF) SERVICES OR ANY CONTENT INFORMATION, ERRORS,
          OMISSIONS, DEFECTS, COMPUTER VIRUSES, UNAUTHORIZED ACCESS, SECURITY
          BREACH, UNAVAILABILITY OF SERVICES, DELAY OR FAILURE OF PERFORMANCE, OR
          ANY OTHER POSSIBLE OCCURRENCE WITH RESPECT TO <b>ALPHA1 </b> SERVICES.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Miscellaneous </h3>
        <p>
          These Terms shall be governed and construed in accordance with the laws
          of Lagos State, Nigeria Any suit involving any dispute or matter arising
          under these Terms may only be brought to Lagos State Nigeria, any state
          court sitting in Lagos Island, Lagos State having jurisdiction over the
          subject matter of the dispute or matter. You hereby consent to the
          exercise of personal jurisdiction by any such court with respect to any
          such proceeding.
        </p>
        <p>
          In any mediation, arbitration, suit or other action arising out of or in
          connection with any of the Terms, covenants or provisions of these
          Terms, you agree that all attorneys' fees, costs and any other expenses
          incurred or accrued directly or indirectly in connection therewith shall
          be paid by the party who is (or the parties who are) found by the
          arbitrator or court to have breached these Terms in addition to any
          equitable relief or damages and/or costs awarded at law; provided that
          if more than one party is found to have breached these Terms, the court
          shall award all such attorneys' fees, costs and expenses to the party
          who is found to be the least at fault.
        </p>
        <p>
          These Terms incorporate by reference the Privacy Policy and any notices
          contained on this <b>ALPHA1 </b> Service and together govern your use of the
          <b>ALPHA1 </b> Services. If any provision of these Terms is determined to be
          unlawful, unenforceable, or invalid, such provision shall be enforced to
          the full extent allowed by law and severed from these Terms without
          affecting the validity and enforceability of the remaining provisions.
        </p>
        <p>
          No waiver of any provision of these Terms by us shall be deemed a
          further or continuing waiver of such provision or any other provision,
          and our failure to assert any right or provision under these Terms shall
          not constitute a waiver of such right or provision.
        </p>
        <p>
          Any violation of the provisions contained herein may result in the
          suspension or termination of a user's right to utilize the <b>ALPHA1 </b>
          Services. Alpha One Foundation further retains the right to seek
          additional remedies, including but not limited to civil or criminal
          penalties, for any violation of these Terms.
        </p>
        <p>
          Contact Alpha One Foundation at alphaonefdn.com with any questions
          regarding these Terms. All notices or inquiries made to Alpha One
          Foundation shall be delivered by:
        </p>
        <ol type="a">
          <li>Email</li>
          <li>Express delivery by a recognized national carrier </li>
          <li>
            Personally hand delivered, dated receipt obtained. The effective date
            of such notice shall be deemed to be the date of sending such
            certified mail or express delivery, or the actual date of hand
            delivery. Notice by mail or express delivery shall be addressed as
            follows:
          </li>
        </ol>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">PRIVACY POLICY </h3>
        <p>This is Alpha One Foundation Privacy Policy. </p>
        <p>
          This Privacy Policy governs the activities of all affiliates and brands
          of Alpha One Foundation ("AOF", "we" or "us"). Alpha One Foundation is a
          Nigeria based non-profit kingdom organization operating as Kingdom media
          center under the <b>ALPHA1 </b> Radio, <b>ALPHA1 </b> Worship, APHW outreaches, Detox x
          and more, and to their respective Sites and mobile applications
          (collectively, the "Sites").
        </p>
        <p>
          We are committed to the protection of your personal information. This
          Privacy Policy sets out the basis on which we collect and use personal
          information about you as part of our ministry activities and the choices
          you have to limit or prevent certain uses or sharing of that
          information. This Privacy Policy applies to our Sites, mobile
          applications, ministers care services, prayer requests, live events,
          contests and other products and services, all of which we call
          “Services” in this Privacy Policy. Please note that this Privacy Policy
          does not govern the Sites and services of third parties that may link to
          or be accessed from This or our other Sites or applications. If you
          donate to us, please see our Donor Privacy Policy about how we handle
          your sensitive payment information.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Information We Collect About You </h3>
        <p>
          We may collect information, including your name, address, email address,
          and phone number when you communicate with us or sign up for one of our
          Services. You also may provide additional information to us such as your
          birthdate, content preferences, communication content or payment
          information. We also gather information about how visitors navigate our
          Sites by using data gathered with cookies. This type of information is
          collected automatically and includes the Internet protocol (IP) address
          used to connect your computer to the Internet; login; e-mail address;
          passwords used for our Sites; computer and connection information such
          as browser type and version, operating system, and platform; the full
          Uniform Resource Locator (URL) clickstream to, through and from our
          Site(s), including date and time; cookie number; and Services that you
          viewed or searched for. If you send us a review, post content on our
          social media sites, or respond to a survey, we collect your submission
          content. We may also obtain information about you from our third party
          partners, such as if you sign up for other of our account through your
          Facebook account, we’ll receive information from that service (e.g.,
          your username, basic profile information, friends list) via the
          authorization procedures for that service, and from other partners who
          may provide us publicly available and other information they have
          collected about you. Through our arrangements with our social media and
          advertising partners, when you visit your social media pages (such as
          Facebook or Instagram), we may collect personal information from or
          about you from those sites.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Sources of Information We Collect </h3>
        <p>
          We collect information directly from individuals, automatically related
          to the use of our Services, and in some cases from third parties (such
          as advertising networks, internet service providers, operating systems
          and platforms, and social media platforms). For example, we collect
          information:
        </p>
        <ul>
          <li>
            from any form you may complete and submit through our Sites, for
            example, information collected from the "Contact Us" page of our
            Sites.
          </li>
          <li>from the content of surveys that you may complete.</li>
          <li>
            from 'cookies' and other similar tools deployed on parts of our Sites.
          </li>
          <li>
            when you provide information when you subscribe and in connection with
            us providing services to you.
          </li>
          <li>
            from other sources, such as advertising and marketing partners, social
            media
          </li>
        </ul>
        <p>
          platforms (including from people with whom you are friends or otherwise
          connected), internet service providers, and operating systems and
          platforms.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">How We Collect Your Information </h3>
        <p>
          As noted above, we collect information that you voluntarily provide to
          us when you interact with us or our Services – such as when you sign up
          <b>ALPHA1 </b> radio app and others platforms; register to use any of our
          Services; sign-up for a contest; participate in an online survey emailed
          to you; visit our booth at a local event; phone into our Audience
          Engagement line; or speak with an AOF ministering officials. Direct
          collection may take place online, in person or over the phone with an
          AOF employee or third-party call center agent We may get personal
          information about you from social media services online if you sign up
          for any of our Services using your social media account (such as
          Facebook, Instagram, Twitter or Pinterest) or from third party partner
          concert promotors, when you sign up for concert tickets or to giveaway
          offer and other programs on ALPHA 1 radio Additionally, we and our
          third-party analytics partners collect personal information about you
          when you visit us through the web or your mobile device via cookies,
          pixels, and similar tracking technologies. This personal information
          includes information about your online activities over time and across
          different Services. Please see the section below on “Cookies”.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">How We Use Your Information </h3>
        <p>
          We may use or disclose your personal information that we collect for one
          or more of the following business purposes:
        </p>
        <ul>
          <li>
            Providing and Improving our Services. To provide, maintain, improve,
            and enhance our services, and to facilitate transactions and payments.
          </li>
          <li>
            Tailoring Content. To personalize your experience with our Services,
            such as by providing tailored content and recommendations.
          </li>
          <li>
            Research and Analytics. To better understand how you use our Services,
            to evaluate and improve our Services and business operations, and to
            develop new Services.
          </li>
          <li>
            Marketing and Advertising. For marketing and advertising purposes,
          </li>
          <li>
            Communications. To communicate with you, provide you with updates and
            other information relating to our Services, provide information that
            you request, respond to your comments and questions, and otherwise
            provide customer support.
          </li>
          <li>
            De-identification and aggregation. To de-identify personal information
            or create aggregated datasets, such as for consolidating reporting,
            research, or analysis.
          </li>
          <li>
            General Business Operations. To support our general business
            operations, including for corporate audit, analysis, and reporting.
          </li>
          <li>
            Legal Compliance. To comply with applicable legal or regulatory
            obligations, including as part of a judicial proceeding. To respond to
            a subpoena, warrant, court order, or other legal process. Or, as part
            of an investigation or request, whether formal or informal, from law
            enforcement or a governmental authority.
          </li>
          <li>
            Protecting Rights and Interests. To protect the safety, property, or
            security of our Sites, our business operations and Services, or the
            general public. To investigate, detect, prevent, or otherwise address
            fraud, security, or technical issues. To investigate, prevent or stop
            activity that AOF, in its sole discretion, may consider to be or
            appears to pose the risk of being an illegal, unethical, or legally
            actionable activity. To use as evidence in litigation, conduct audits,
            and to enforce this Privacy Policy and the Terms of Use for our
            Services. To manage or respond to actual and potential legal disputes
            and claims, and to otherwise establish, defend, or protect our rights
            or interests, including in the context of anticipated or actual
            litigation with third parties.
          </li>
          <li>
            Protecting Us and Others. To investigate, prevent, or take action to
            guard against illegal activities, suspected fraud, situations
            involving potential threats to the safety of any person, or violations
            of our Terms of Use or this Privacy Policy, or as evidence in
            litigation in which we are involved.
          </li>
        </ul>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Information We Share</h3>
        <p>
          We may share some of your personal information with third parties for
          business reasons, as outlined below:
        </p>
        <ul>
          <li>
            Affiliates. We may disclose certain personal information we collect
            about you to our affiliates (e.g., our subsidiaries and entities under
            common ownership with AOF) to complete the performance of Services or
            related transactions. such as developing promotional and advertising
            materials that may be relevant, valuable, or interesting and then
            delivering those materials to you.
          </li>
          <li>
            Third Party Vendors and Service Providers. Like many businesses, we
            sometimes engage other companies to perform certain business-related
            functions for us, such as consulting, service processing and the like
            (“Vendors” or “Service Providers”). Examples of such functions include
            mailing, emailing and texting communications to you, maintaining our
            databases, and processing payments. When we employ another entity to
            perform a function of this nature, we only provide them with the
            personal information that they need to perform their specific
            function.
          </li>
          <li>
            Analytics Partners. As noted above (“How We Collect Your Information -
            Automatic Browser Collection”), we may use third-party online tracking
            and analytic services (such as Google Analytics, Facebook, or others)
            and share certain personal information with them to help us understand
            how you use our Services and how to improve upon your experience with
            those Services. Each such third-party’s use of your personal
            information is dictated by their respective privacy policy. Please see
            our “Cookies” section below for more information.
          </li>
          <li>
            Advertising Partners & social media networks. We do this through the
            use of remarketing tracking Tags. We also may use custom audiences
            based on emails addresses and phone numbers of our subscribers and
            Site visitors. This allows us to present special offers for our
            products and services to you via Facebook and Google. We may also
            share your personal information with third-party advertising partners
            for their marketing, research, or similar purposes. Please see
            “Cookies,” below, to learn about your options regarding personalized
            ads
          </li>
          <li>
            Business Transfer Partners. If (i) we or our affiliates are acquired
            by, merged with, or invested in by another company, or (ii) if any of
            our assets are transferred to another company, whether as part of a
            bankruptcy or insolvency proceeding or otherwise, we may transfer the
            information collected from you to the other company. As part of the
            business transfer process, we may share certain pieces of your
            personal information with lenders, auditors, attorneys, consultants,
            and other third-party advisors.
          </li>
          <li>
            Parties to Legal Action. We may disclose your personal information to
            comply with the law, a judicial proceeding, court order, subpoena, or
            other legal process.
          </li>
          <li>
            Please note that we may also disclose your personal information where
            we believe it is necessary to investigate, prevent, or take action
            regarding illegal activities, suspected fraud, situations involving
            potential threats to the safety of any person, violations of our Terms
            of Use or this Policy, or where it is reasonably necessary as evidence
            in litigation in which we are involved.
          </li>
        </ul>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Cookies </h3>
        <p>
          As mentioned above, when you visit us on our Sites or use one of our
          mobile applications, we collect information about you by using cookies,
          pixels, and other tracking technologies, which we may combine with other
          personal information we collect about you. Some examples of the data we
          collect from cookies and similar technologies include your account
        </p>
        <p>
          We work with online third-party advertising partners to present special
          ads we think may interest you when you visit our sites or visit the
          sites of Third Parties, including Facebook and other preferences, pages
          accessed, language preferences, and previous visits to our websites. We
          use this information to better understand, customize, and improve your
          experience using our Services, as well as to manage our advertising. For
          example, we use third-party analytics and advertising partners'
          automated devices and applications, such as Google Analytics, to
          evaluate usage of our Sites. We use these tools to help us improve the
          performance of our Site and our users’ experience on our Site.
        </p>
        <p>
          These third-party entities may use cookies and other tracking
          technologies to perform their services for us. To learn more about
          Google’s privacy practices, please review the Google Privacy Policy. You
          can also download the Google Analytics Opt-Out Browser Add-on to prevent
          your data from being used by Google Analytics.
        </p>
        <p>
          Most web browsers automatically accept cookies, but if you prefer, you
          can edit your browser options to control whether you will accept
          cookies, reject cookies, or to notify you each time a certain type
          cookie is sent to your browser. You may also disable certain third-party
          tracking cookies that we use on our Sites when our sharing of your
          personal information constitutes a "sale", as further described under
          the California Privacy Notice, below. Please note that, if you disable
          cookies on our Sites, some functionality of the Sites will be lost.
        </p>
        <p>
          Currently, our systems do not recognize browser “do-not-track” signals.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Ad Network </h3>
        <p>
          We use network advertisers to serve advertisements on non-affiliated
          websites or other media (e.g., social networking platforms). This
          enables us and these network advertisers to target advertisements to you
          for products and services in which you might be interested. Ad network
          providers, advertisers, sponsors and/or traffic measurement services may
          use cookies, JavaScript, pixels, LSOs and other tracking technologies to
          measure the effectiveness of their ads and to personalize advertising
          content to you. These cookies and other technologies are governed by
          each entity's specific privacy policy, not this one. We may provide
          these advertisers with information, including personal information,
          about you.
        </p>
        <p>
          Users may opt out of many ad networks. For example, you may go to the
          Digital Advertising Alliance ("DAA") Consumer Choice Page for
          information about opting out of interest-based advertising and their
          choices regarding having information used by DAA companies. You may also
          go to the Network Advertising Initiative ("NAI") Consumer Opt-Out Page
          for information about opting out of interest-based advertising and their
          choices regarding having information used by NAI members.
        </p>
        <p>
          Opting out from one or more companies listed on the DAA Consumer Choice
          Page or the NAI Consumer OptOut Page will opt you out from those
          companies' delivery of interest based content or ads to you, but it does
          not mean you will no longer receive any advertising through our website
          or on other websites. You may continue to receive advertisements, for
          example, based on the particular website that you are viewing (i.e.,
          contextually based ads). Also, if your browsers are configured to reject
          cookies when you opt out on the DAA or NAI websites, your opt out may
          not be effective. Additional information is available on the DAA's
          website at www.aboutads.info or the NAI's website at
          www.networkadvertising.org.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Children </h3>
        <p>
          Children under age 13 (minors) always should get permission from a
          parent or guardian before providing any personal information about
          themselves. However, sometimes minors contact us to make a donation,
          seek a prayer, detox x session or request Christian counseling, or they
          may interact with us on our other expressions and ministries or attend
          event or training, from which we may collect their personal information.
          If you are a parent of a child under the age of 13, you may contact us
          at the Contact Information below, to request the information about your
          child that we have collected or request that we delete such information.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">International Usage of Our Services </h3>
        <p>
          We are based in Nigeria., and the personal information we collect is
          governed by the Nigeria law. If you are accessing our Services from
          outside of Nigeria., please be aware that personal information collected
          through our Services may be transferred to, processed, stored, or used
          in Nigeria. Data protection laws of Nigeria are likely different from
          those of your country of residence.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Security of Your Information </h3>
        <p>
          We are committed to protecting your personal information. We have
          implemented reasonable precautions to protect the personal information
          we collect from loss, misuse, best efforts, no data security measures
          can guarantee absolute security.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Changes to this Privacy Policy </h3>
        <p>
          This Privacy Policy is effective as of the date stated at the bottom of
          this document. We may change this Privacy Policy from time to time, so
          please be sure to check back periodically. We will post any changes to
          this Privacy Policy via our Services.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">How to Contact Us </h3>
        <p>
          If you have questions about our Services with respect to this Privacy
          Policy, or would like to make a complaint, please contact us in one of
          the following ways:
        </p>
        <p>Submitting Consumer Privacy Rights Requests </p>
        <p>
          To exercise your consumer privacy rights as stated above, you may submit
          a request to us, as follows:
        </p>
        <p>
          Reach Us on <b>ALPHA1 </b>radio.org, kindly make use of the ‘Contact Us’ form on
          this Site.
        </p>
        <p>
          In order to submit a consumer privacy rights request, you will need to
          provide us with the following identifying information so that we may
          process your request: your name, your mailing address, your telephone
          number, and your email address.
        </p>
        <p>
          We will need to verify your identity before we can provide a substantive
          response to your request. Only you, or an authorized agent that you
          authorize to act on your behalf, may make a consumer privacy rights
          request related to your personal information. You may also make a
          request on behalf of your minor child.
        </p>
        <p>In order to verify your request, we ask that you: </p>
        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify you
            are the person about whom we collected personal information or an
            authorized agent who is properly registered and has been authorized in
            writing by the consumer whose personal information is being requested
            (we will ask authorized agents to provide the consumer's written
            authorization for confirmation).
          </li>
          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </ul>
        <p>
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use personal information provided in a verifiable
          consumer request to verify the requestor's identity or authority to make
          the request.
        </p>
        <p>Exceptions to Consumer Privacy Rights Requests. </p>
        <p>
          In certain circumstances we may decline your consumer privacy rights
          request, such as where fulfilling a request may interfere with a
          transaction you have asked us to process for you or to comply with other
          obligations under other applicable laws. If we deny your request, we
          will communicate our decision to you. We will not penalize you for
          making a request.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Liability Waiver </h3>
        <p>
          I am fully aware that my pictures and videos will be captured and can be
          released and shared across all media platforms by alpha one foundation
          and it’s expressions, I hereby RELEASE, WAIVE, DISCHARGE, AND COVENANT
          NOT TO SUE alpha one foundation, their management, directors,
          volunteers, officers, servants, agents, and employees for any of the
          above sated reasons, (hereinafter referred to as RELEASEES).
        </p>
        <p>
          To the best of my knowledge, I am in good physical condition and fully
          able to participate in any Alpha 1 events, programs and shows I hereby
          RELEASE, WAIVE, DISCHARGE, AND COVENANT NOT TO SUE, alpha one
          foundation, their volunteers, officers, servants, agents, and employees
          (hereinafter referred to as RELEASEES) from any and all liability,
          claims, demands, actions and causes of action whatsoever arising out of
          or related to any loss, damage, or injury, including death, that may be
          sustained by me, or to any property belonging to me, while participating
          in physical activity, or while on or upon the premises where the program
          is being conducted.
        </p>
        <p>
          It is my expressed intent that this release and hold harmless agreement
          shall bind the members of my family and spouse, if I am alive, and my
          heirs, assigns and personal representative, if I am deceased, and shall
          be deemed as a RELEASE, WAIVE, DISCHARGE, and CONVENTION TO SUE the
          above named RELEASEES. I hereby further agree that this Waiver of
          Liability and Hold Harmless Agreement shall be constructed in accordance
          with the laws of the State of the Federal Republic of Nigeria.
        </p>
        <p>
          In signing this release, I acknowledge and represent that I HAVE READ
          THE FORGOING Waiver of Liability and Hold Harmless Agreement, UNDERSTAND
          IT AND SIGN IT VOLUNTARILY as my own free act and deed; no oral
          representations, statements or inducements, apart from the foregoing
          written agreements have been made; and I EXECUTE THIS RELEASE FOR FULL,
          ADEQUATE AND COMPLETE CONSIDERATION FULLY INTENDING TO BE BOUND BY SAME.
        </p>
      </div>

      <div className="mb-5">
        <h3 className="mb-3">Changes to Our Privacy Notice </h3>
        <p>
          We reserve the right to amend this privacy notice and other notices at
          our discretion and at any time, including updating regarding our data
          practices and your rights, modify our methods for responding to your
          requests, and/or supplement our response to your requests. When we make
          changes to this privacy notice, we will notify you by email or through a
          notice on our Sites' homepages.
        </p>
      </div>
    </section>
  );
};

export default TermsOfService;

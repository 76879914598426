import React from 'react'
import './aboutus.css'
import Images from '../../Images'
import { axiosInstance } from '../../axiosConfig';
import { toast } from 'react-toastify';

const AboutUs = () => {
  const [settings, setSettings] = React.useState(null);

  React.useEffect(() => {
    getSettings();
    return () => {
      setSettings(null);
    };
  }, []);

  const getSettings = async () => {
    axiosInstance.get(`/setting/detail?type=aboutUsDetails`)
      .then((response) => {
        const responseData = response?.data?.data;
        const { status } = response?.data;
        if (status) {
          setSettings(responseData);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className='about-us'>
      <section className='hero-section'>
        <div className="welcome-area" id="welcome">
        </div>
      </section>
      {/* <div className='about-us-content'> */}
      <div className='container'>
        <div className='about-us-title text-center'><h2>about us</h2></div>
        {/* <div className='about-us-sub-title text-center'><h6>.....</h6></div> */}
        <div className='about-us-detail'>
          <div className='about-us-img'><img src={Images?.MobileRight} alt='' /></div>
          <div className='about-us-who-we-are'>
            <div className='about-us-who-we-are-title'><h3>Who We Are</h3></div>
            <div className='about-us-who-we-are-detail mt-4'>
              <h6>Alpha1 Radio is a not for profit, listeners sponsored 24-hour worship radio station with a mandate to introduce people to continuous hopefilled worship moments.</h6>
              <h6>By creating a wave of diverse worship content for our community, we aspire to continuously lead them to a relaxed, vibrant , wholesome worship experience  as a kingdom based media organization.</h6>
            </div>
          </div>
          <div className='about-us-our-mission'>
            <div className='about-us-our-mission-title'><h3>Our Mission</h3></div>
            <div className='about-us-our-mission-detail mt-4'>
              <h6>{settings?.mission}</h6>
            </div>
          </div>
          <div className='about-us-our-vision'>
            <div className='about-us-our-vision-title'><h3>Our Vision</h3></div>
            <div className='about-us-our-vision-detail mt-4'>
              <h6>{settings?.vision}</h6>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default AboutUs

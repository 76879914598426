import React from 'react'
import './ambassador.css'

const Ambassador = () => {
    return (
        <div className='ambassador'>

            <section className='ambassador-form'>
                <div className='container'>
                    <div className='ambassador-form-title text-center'><h1>Become our Ambassador</h1></div>
                    <div className='ambassador-form-subtitle text-center mt-3 text-white'><h6>.....................................</h6></div>
                    <div className='ambassador-form-data mt-5'>
                        <form id="contact" method="get">
                            <div className='ambassador-form-fields'>
                                <input name="name" type="text" className="form-control name-input" id="name" placeholder="Full Name" required />
                                <input name="email" type="email" className="form-control email-input" id="email" placeholder="E-Mail Address" required />
                                <div className='birthdate'>
                                    <h6 htmlFor="birthdate" className='mb-2 text-white'> Date of Birth</h6>
                                    <input type="date" id="birthday" name="birthdate" />
                                </div>
                                <div className='gender'>
                                    <h6 htmlFor="sex" className='mb-2 text-white'>Select Gender</h6>
                                    <select name="sex" id="sex">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <input name="Country" type="text" className="form-control country-input" id="city" placeholder="Country" required />
                                <input name="City" type="text" className="form-control city-input" id="country" placeholder="City" required />
                                <input name="postalcode" type="text" className="form-control" id="country" placeholder="postalcode" required />
                                <div className='checkbox-field'>
                                    <input type="checkbox" className='me-1' />
                                    <label className='text-white'> I understand and agree to the terms and conditions, privacy policy and trust alphaone with my Info.</label>
                                </div>
                                <div className="ambassador-form-btn">
                                    <button type="submit" id="form-submit" className="main-button">Sign Up</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='ambassador-form-content-bg'>
                </div>
            </section>

            <section className='become-ambassador'>
                <div className='container'>
                    <div className='become-ambassador-content'>
                        <div className='become-ambassador-title'><h2>Become our Ambassador</h2></div>
                        <div className='become-ambassador-data mt-5'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 col-sm-12 '>
                                    <div className='unlimited-access mb-5'>
                                        <div className='become-ambassador-data-title'><h3>Unlimited Access </h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>Access to worship concerts, programs & giveaways.. </h6></div>
                                    </div>
                                    <div className='Discounts  mb-5'>
                                        <div className='become-ambassador-data-title'><h3>Discounts  </h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>Huge discounts on event tickets... </h6></div>
                                    </div>
                                    <div className='Host Sessions  mb-5'>
                                        <div className='become-ambassador-data-title'><h3>Host Sessions  </h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>Become part of the ambassador's forum and host sessions.. </h6></div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 col-sm-12 '>
                                    <div className='Notifications mb-5'>
                                        <div className='become-ambassador-data-title'><h3>Notifications</h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>Stay Updated on our programs, Concerts etc..</h6></div>
                                    </div>
                                    <div className='Priority  mb-5'>
                                        <div className='become-ambassador-data-title'><h3>Priority </h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>We prioritize our ambassador's requests..</h6></div>
                                    </div>
                                    <div className='Host Sessions  mb-5'>
                                        <div className='become-ambassador-data-title'><h3>First-hand Updates </h3></div>
                                        <div className='become-ambassador-data-subtitle'><h6>Acess to first-hand playlist and updates..</h6></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Ambassador

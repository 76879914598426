import React, { useEffect, useRef, useState } from 'react'
import Player from './Player';

const AudioPlayer = ({ episodes, index }) => {
    const [songs, setSongs] = useState(episodes);
    const [isplaying, setisplaying] = useState(false);
    const [currentSong, setCurrentSong] = useState(episodes[index]);

    const audioElem = useRef();

    const audioModelRef = useRef(null)

    useEffect(() => {
        if (isplaying) {
            audioElem.current.play();
        }
        else {
            audioElem.current.pause();
        }
    }, [isplaying, currentSong])

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (audioModelRef.current && !audioModelRef.current?.contains(event.target)) {
                setisplaying(false);
                audioElem.current.pause();
            }
        };
        const handleEscapeKey = (event) => {
                if (event.key === "Escape") {
                    setisplaying(false);
                    audioElem.current.pause();
                }
            };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isplaying])

    const onPlaying = () => {
        const duration = audioElem.current.duration;
        const ct = audioElem.current.currentTime;

        setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration })

    }
    return (
        <div className="App" ref={audioModelRef}>
            <audio src={currentSong?.audio} ref={audioElem} onTimeUpdate={onPlaying} />
            <Player songs={songs} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} />
        </div>
    )
}

export default AudioPlayer

import React, { useEffect } from 'react'
import { useAnimate, stagger, motion } from "framer-motion";
import { Link } from 'react-router-dom';
import './dropdown.css';

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

function useMenuAnimation(isOpen) {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(".arrow", { rotate: isOpen ? 180 : 0 }, { duration: 0.2 });

        animate(
            "ul",
            {
                clipPath: isOpen
                    ? "inset(0% 0% 0% 0% round 10px)"
                    : "inset(10% 50% 90% 50% round 10px)"
            },
            {
                clipPath: "inset(10% 50% 90% 50% round 10px)"

            },
            {
                type: "spring",
                bounce: 0,
                duration: 0.5
            }
        );

        animate(
            "li",
            isOpen
                ? { opacity: 1, scale: 1, filter: "blur(0px)" }
                : { opacity: 0, scale: 0.3, filter: "blur(20px)" },
            {
                duration: 0.2,
                delay: isOpen ? staggerMenuItems : 0
            }
        );
    }, [isOpen]);

    return scope;
}

const Dropdown = ({ className, title, list, collapsed, setCollapsed, onMouseOver }) => {

    const [isOpen, setIsOpen] = React.useState(false);

    const scope = useMenuAnimation(isOpen);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
        setIsOpen(false)
    };

    const autoCloseAnotherDropdown = () => {
        if (title === "Hope") setIsOpen((prev) => ({ Hope: !prev?.Hope, More: false }));
        if (title === "More") setIsOpen((prev) => ({ Hope: false, More: !prev.More }));
    }

    return (
        <li className="menu nav-item mx-4" ref={scope}

        >
            <motion.button
                whileTap={{ scale: 0.97 }}
                // onClick={autoCloseAnotherDropdown}
                className={className}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                {title}
                <div className={`arrow dropdown-arrow ${isOpen ? 'dropdown-arrows' : ''}`} style={{ marginLeft: '5px' }}>
                </div>
            </motion.button>
            <ul className="menu-list" aria-labelledby="navbarDropdown"
                style={{
                    pointerEvents: isOpen ? "auto" : "none",
                    clipPath: "inset(10% 50% 90% 50% round 10px)"
                }}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                {list?.map((li) => (
                    <Link onClick={toggleNavbar} key={li?.link} to={li?.link}><li>{li?.name}</li></Link>
                ))}
            </ul>
        </li>
    )
}

export default Dropdown
